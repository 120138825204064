<template>
  <div class="body_bgi full p_10 bgs_full relative">
    <big-screen-header headerName="登录" />

    <div class="login_form_box w_20_p absolute_center">
      <el-form class="login_form" :model="loginForm" label-width="60px">
        <el-form-item label="账号" prop="userName">
          <el-input v-model="loginForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="loginForm.password"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="checkPass">
          <el-input v-model="loginForm.code"></el-input>
        </el-form-item>
        
        <el-form-item>
          <el-button class="w_full" type="warning" @click="submitForm('loginForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def.js'
  import componentsMixin from '@/mixins/components.js'
  import imgMixin from '@/mixins/def-img.js'

  export default {
    name: 'CattleDetail',
    mixins: [defMixin, componentsMixin, imgMixin],
    data() {
      return {
        loginForm: {
          userName: '',
          password: '',
          code: ''
        },
      }
    },
    methods: {
      submitForm(formName) {
        
      },
    }

  }
</script>

<style>
  .login_form > .el-form-item > .el-form-item__label { color: #fff; }
  .login_form > .el-form-item > .el-form-item__content > .el-input > input { 
    background: none !important;
    border: none;
    border-bottom: 1px solid #fff;  
    border-radius: 0;
  }
</style>